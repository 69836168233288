import React, { useEffect, useState, useCallback } from "react";
import styled, { keyframes } from "styled-components";

const scaleInAnimation = keyframes`
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
`;

const scaleOutAnimation = keyframes`
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
   -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
`;

const Wrapper = styled.div`
  overflow: hidden;
  pointer-events: none;

  div {
    height: 100vh;
  }
`;

const Emoji = styled.span`
  animation: ${({ shouldHideEasterEgg }) =>
      shouldHideEasterEgg ? scaleOutAnimation : scaleInAnimation}
    0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
`;

export default function Home({ emojis }) {
  const [shouldHideEasterEgg, setShouldHideEasterEgg] = useState(false);
  const [showEasterEgg, setShowEasterEgg] = useState(false); // show easter egg
  const [emojiList, setEmojiList] = useState([]);

  const hideEasterEggCallback = useCallback(() => {
    setShouldHideEasterEgg(false);
    setShowEasterEgg(false);
  }, []);

  useEffect(() => {
    if (!shouldHideEasterEgg) return;
    setTimeout(hideEasterEggCallback, 1000);
  }, [shouldHideEasterEgg]);

  const visibilityChangeCallback = useCallback(() => {
    if (document.visibilityState === `visible`) {
      const emojiCount = Math.min(Math.round(Math.random() * 75), 20);
      const emoji = new Array(emojiCount).fill(null).map(() => {
        return {
          emoji: emojis[Math.floor(Math.random() * emojis.length)],
          x: Math.round(Math.random() * window.innerHeight),
          y: Math.round(Math.random() * window.innerWidth),
          duration: Math.round(Math.random() * 1000),
        };
      });
      setEmojiList(emoji);
      setTimeout(() => {
        setShouldHideEasterEgg(true);
      }, 1000);
    } else {
      setShowEasterEgg(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener(`visibilitychange`, visibilityChangeCallback);

    return () => {
      document.removeEventListener(
        `visibilitychange`,
        visibilityChangeCallback
      );
    };
  }, [visibilityChangeCallback]);

  return (
    <Wrapper>
      <div>
        {showEasterEgg &&
          emojiList.map(({ emoji, x, y, duration }, index) => {
            return (
              <Emoji
                shouldHideEasterEgg={shouldHideEasterEgg}
                key={emoji + index.toString()}
                style={{
                  position: `absolute`,
                  top: `${x}px`,
                  left: `${y}px`,
                  fontSize: `32px`,
                  zIndex: 5000,
                  animationDuration: `${duration}ms`,
                }}
              >
                {emoji}
              </Emoji>
            );
          })}
      </div>
    </Wrapper>
  );
}
