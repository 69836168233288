import React, { useEffect, useRef, useState } from "react";

import { AUDIENCE_TYPES } from "posts/constants";
import { getAudienceEmoji } from "posts/constants";
import styled from "styled-components";

const Wrapper = styled.span`
  display: inline-block;
  margin-right: 0em;
  margin-right: ${(props) => (props.noMarginRight ? "0em" : "0.5em")};
`;

export default function AudienceEmoji({ audience, noMarginRight }) {
  const wrapper = useRef(null);
  const [shouldWink, setShouldWink] = useState(false);
  const [shouldGasp, setShouldGasp] = useState(false);

  const handleMouseOver = () => {
    setShouldWink(true);
  };
  const handleMouseLeave = () => {
    setShouldWink(false);
    setShouldGasp(false);
  };

  const handleMouseDown = () => {
    setShouldGasp(true);
  };
  const handleMouseUp = () => {
    setShouldGasp(false);
  };

  useEffect(() => {
    if (wrapper.current && audience === AUDIENCE_TYPES.IF_YOU_SAY_SO) {
      wrapper.current.addEventListener("mouseover", handleMouseOver);
      wrapper.current.addEventListener("mouseleave", handleMouseLeave);
      wrapper.current.addEventListener("mousedown", handleMouseDown);
      wrapper.current.addEventListener("mouseup", handleMouseUp);

      return () => {
        wrapper.current.removeEventListener("mouseover", handleMouseOver);
        wrapper.current.removeEventListener("mouseleave", handleMouseLeave);
        wrapper.current.removeEventListener("mousedown", handleMouseDown);
        wrapper.current.removeEventListener("mouseup", handleMouseUp);
      };
    }
  }, []);

  const renderEmoji = () => {
    if (shouldGasp) return "😮";
    if (shouldWink) return "😉";
    return getAudienceEmoji(audience);
  };
  return (
    <Wrapper noMarginRight={noMarginRight} ref={wrapper}>
      {renderEmoji()}
    </Wrapper>
  );
}
