import styled from "styled-components";
import { BREAKPOINT } from "utils/styles";

const FlexSpacer = styled.div`
  flex-grow: 1;

  @media ${BREAKPOINT.M} {
    flex-grow: ${(props) => props.mobileFlex};
  }
`;
export default FlexSpacer;
