import {
  BORDER_RADIUS_SMALL,
  BORDER_RADIUS,
  COLORS,
  DEFAULT_BOX_SHADOW,
  DEFAULT_TRANSITION_ALL,
  getAudienceTypeColor,
  BREAKPOINT,
} from "utils/styles";

import { AUDIENCE_TYPES, getAudienceDescription } from "posts/constants";
import styled from "styled-components";
import AudienceEmoji from "components/AudienceEmoji";

export const MainNavToggle = styled.button.attrs({ className: "mabry-medium" })`
  ${BORDER_RADIUS};
  ${DEFAULT_TRANSITION_ALL};
  align-items: center;
  appearance: none;
  background: ${({ currentAudienceType }) => {
    return getAudienceTypeColor(currentAudienceType) || COLORS.WHITE;
  }};
  border: none;
  display: flex;
  font-size: 0.875rem;
  line-height: 1rem;
  margin-left: 1rem;
  outline: none;
  padding: 0;
  padding: 1rem;
  z-index: 1000;

  &:active,
  &:focus {
    outline: none;
  }

  &::after {
    display: none !important;
  }
`;

export const NavItemIcon = styled.i`
  font-size: 1rem;
  display: block;
`;

export const CustomToggle = React.forwardRef(
  ({ className, children, currentAudienceType, onClick }, ref) => {
    return (
      <MainNavToggle
        className={className}
        currentAudienceType={currentAudienceType}
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </MainNavToggle>
    );
  }
);

const AudienceNavItemWrapper = styled.div`
  background: ${({ isActive, audience }) =>
    isActive ? getAudienceTypeColor(audience) : "transparent"};
  cursor: pointer;
  width: 360px;
  white-space: normal;

  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 1rem;

  &:hover,
  &:focus {
    background: ${({ audience }) => getAudienceTypeColor(audience)};
  }

  @media ${BREAKPOINT.M} {
    width: calc(100vw - 2rem);
    margin: auto;
    padding: 0.25rem 1rem;

    & :first-child {
      padding-top: 1rem;
    }
  }
`;
const AudienceNavItemTitle = styled.span.attrs({ className: "mabry-regular" })`
  display: block;
  font-size: 1.125rem;
  margin: 0 0 0.5rem 0;
`;
const AudienceNavItemDescription = styled.span`
  display: block;
  font-size: 1rem;
  line-height: 1.2;
  width: 100%;
  margin-bottom: 1rem;
`;

export const AudienceNavItem = ({ activeAudience, audience, className }) => {
  const renderDescription = (a) => {
    switch (a) {
      case AUDIENCE_TYPES.PEOPLE_POWER:
        return "a";
      case AUDIENCE_TYPES.IF_YOU_SAY_SO:
        return "b";
      case AUDIENCE_TYPES.TOUGH_COOKIES:
        return "c";
      case AUDIENCE_TYPES.DONT_TREAD_ON_ME:
        return "d";
    }
  };
  return (
    <AudienceNavItemWrapper
      audience={audience}
      isActive={audience === activeAudience}
      className={className}
    >
      <AudienceNavItemTitle>
        <AudienceEmoji audience={audience} />
        {audience}
      </AudienceNavItemTitle>
      <AudienceNavItemDescription>
        {getAudienceDescription(audience)}
      </AudienceNavItemDescription>
    </AudienceNavItemWrapper>
  );
};

export const SecondaryMenuWrapper = styled.div`
  display: flex;
  flex: 100%;
  justify-content: space-between;
`;

export const SecondaryMenuButtonWrapper = styled.a`
  letter-spacing: 0.25px;
  display: inline-block;
  ${BORDER_RADIUS_SMALL}
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  background: ${COLORS.WHITE};
  color: ${COLORS.BLACK};
  cursor: pointer;
  margin: 0 1rem;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  position: relative;

  &:after {
    display: none;
  }

  &:hover {
    color: ${COLORS.BLACK};
    text-decoration: none;
    opacity: 0.5;
  }

  ${({ inactive }) => (inactive ? "opacity: 0.5;" : "")}
`;

export const SecondaryMenuGroup = styled.div`
  display: flex;
  background-color: ${COLORS.WHITE};
  ${BORDER_RADIUS}

  .dropdown {
    display: flex;
  }
`;

const SecondaryMenuButtonIcon = styled.i.attrs({
  className: "material-icons-round md-dark md-18 mr-1",
})`
  padding-right: 0.2rem;
  position: relative;
  top: 1px;
`;
const SecondaryMenuButtonText = styled.span.attrs({
  className: "mabry-regular",
})``;

export const SecondaryMenuButton = ({ active, text, icon, ...props }) => {
  return (
    <SecondaryMenuButtonWrapper {...props}>
      <SecondaryMenuButtonIcon>{icon}</SecondaryMenuButtonIcon>
      <SecondaryMenuButtonText>{text}</SecondaryMenuButtonText>
    </SecondaryMenuButtonWrapper>
  );
};
