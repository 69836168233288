import {
  BREAKPOINT,
  BORDER_RADIUS,
  BORDER_RADIUS_SMALL,
  COLORS,
  DEFAULT_TRANSITION_ALL,
} from "utils/styles";

import React from "react";
import styled from "styled-components";

const Icon = styled.i`
  ${DEFAULT_TRANSITION_ALL};
  display: block;
  margin-left: 1rem;
  width: 24px;
`;

const ButtonContainer = styled.a.attrs(() => ({
  className: "mabry-medium",
}))`
  ${BORDER_RADIUS};
  transition: all 200ms ease-out;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0.7rem 1rem;
  font-weight: 500;
  font-style: normal;
  font-size: 1.125rem;
  background-color: ${COLORS.WHITE};
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: ${COLORS.BLACK};

  &:hover {
    color: ${COLORS.DARK_BLACK};
    text-decoration: none;
    transform: scale(1.02);
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1),
      0px 1px 10px rgba(0, 0, 0, 0.15);

    ${Icon} {
      margin-left: 1.5rem;
    }
  }

  @media ${BREAKPOINT.M} {
    width: 100%;
  }
`;

function Button(props) {
  const { className, children, href, noIcon, onClick, icon } = props;

  return (
    <ButtonContainer onClick={onClick} className={className} href={href}>
      {children}
      {noIcon ? null : (
        <Icon className="material-icons-round md-dark md-18">
          {icon ? icon : "arrow_forward"}
        </Icon>
      )}
    </ButtonContainer>
  );
}

export const SmallButton = styled.a.attrs({ className: "ml-0 py-1 px-2" })`
  ${BORDER_RADIUS_SMALL}
  background-color: ${COLORS.LIGHTEST_GRAY};
  flex: 0 1;
  font-size: 0.9rem;
  font-weight: normal;
  white-space: nowrap;

  &, &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

export const MediumButton = styled.a.attrs({ className: "ml-0 py-2 px-3" })`
  ${BORDER_RADIUS_SMALL}
  background-color: ${COLORS.LIGHTEST_GRAY};
  flex: 0 1;
  font-size: 1rem;
  font-weight: normal;
  white-space: nowrap;

  &, &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

export default Button;
