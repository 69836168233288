import { BORDER_RADIUS, COLORS, BREAKPOINT, TYPE_SIZE } from "utils/styles";
import { NavItemIcon } from "./components";
import React, { useState } from "react";
import FlexSpacer from "components/FlexSpacer";
import styled from "styled-components";
import MenuDrawer from "components/Nav/MenuDrawer";
import {
  collapseAnimationDuration,
  collapseAnimationEasing,
} from "components/Feed/styles";
import { useMediaQuery } from "react-responsive";
export const AUDIENCE_PROFILE_SECTIONS = {
  PROFILE: "profile",
  DEMOGRAPHICS: "demographics",
  MEDIA: "media",
};
import ScreenSaver from "components/ScreenSaver";

const Wrapper = styled.nav`
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  position: fixed;
  pointer-events: none;

  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100vw;
`;

export const MenuLink = styled.a.attrs({ className: "m-0" })`
  &:hover {
    cursor: pointer;
  }
`;

const MenuSection = styled.div``;
const SecondaryMenu = styled.div`
  flex: calc(60vw - 1rem) 1;
  padding-left: 1rem;
  display: flex;
  justify-content: space-between;
  transition: all ${collapseAnimationDuration} ${collapseAnimationEasing};
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  opacity: 1;

  justify-content: space-between;

  * {
    pointer-events: all;
  }

  ${FlexSpacer} {
    pointer-events: none;
  }

  &:hover {
    opacity: 1;
  }

  & .hidden-on-large-screen {
    display: block;
    @media (min-width: ${BREAKPOINT.S}) {
      display: none;
    }
  }

  .margin-top-on-large-screen {
    margin-top: 0;
    @media (min-width: ${BREAKPOINT.S}) {
      margin-top: 1rem;
    }
  }

  .margin-top-on-small-screen {
    margin-top: 1rem;
    @media (min-width: ${BREAKPOINT.S}) {
      margin-top: 0;
    }
  }
`;

const PrimaryMenu = styled.div`
  flex-basis: ${({ collapsed }) => (collapsed ? "0" : "calc(40vw - 1rem)")};
  transition: all ${collapseAnimationDuration} ${collapseAnimationEasing};
  white-space: nowrap;
  display: flex;
`;

const NavLinkText = styled.span.attrs({ className: "mabry-medium" })``;

const NavLink = styled.a`
  align-items: center;
  border: none;
  color: ${COLORS.DARKEST_GRAY};
  cursor: pointer;
  display: ${({ inline }) => (inline ? "inline" : "flex")};
  outline: none;
  text-decoration: none !important;

  &:hover,
  &:focus {
    color: inherit;
    opacity: 1;
    text-decoration: none !important;

    ${NavLinkText} {
    }
  }
`;

export const MenuBox = styled.div`
  ${BORDER_RADIUS};
  align-items: center;
  appearance: none;
  background: ${({ showBackground, backgroundColor }) =>
    showBackground ? backgroundColor || COLORS.WHITE : "transparent"};
  border: none;
  display: flex;
  font-size: ${TYPE_SIZE.S};
  line-height: 1rem;
  outline: none;
  padding: 0;
  padding: 1rem;
  z-index: 1000;

  &:active,
  &:focus {
    outline: none;
  }

  &::after {
    display: none !important;
  }
`;

const BreadCrumbs = styled.div.attrs({ className: "mabry-regular" })`
  letter-spacing: 0.25px;
  > a,
  > span {
    color: ${COLORS.BLACK};
    font-weight: bold;
    font-family: inherit;
    margin: 0 0.5rem;
    > span {
      font-family: inherit;
    }

    &:hover,
    &:active {
      color: ${COLORS.BLACK};
      text-decoration: none;
      opacity: 0.5;
    }
  }
`;

export default function Nav({
  secondaryNav,
  breadCrumbs,
  navBackgroundColor,
  isCollapsed,
}) {
  const [navIsOpen, setNavIsOpen] = useState(false);
  const isTouchscreen = useMediaQuery({ query: "(hover: none)" });
  const isMobile = useMediaQuery({ query: BREAKPOINT.M });

  const breadCrumbOutput = () => {
    if (!breadCrumbs) return;

    if (Array.isArray(breadCrumbs)) {
      return (
        <BreadCrumbs>
          {breadCrumbs.reduce(
            (acc, x) =>
              acc === null ? (
                x
              ) : (
                <>
                  {acc} / {x}
                </>
              ),
            null
          )}
        </BreadCrumbs>
      );
    }

    return <BreadCrumb breadCrumb={breadCrumbs} />;
  };

  return (
    <Wrapper>
      <MenuWrapper>
        {!isMobile && <SecondaryMenu>{secondaryNav}</SecondaryMenu>}
      </MenuWrapper>
      <ScreenSaver emojis={[`✊`, `🙃`, `💪️`, `⚡`]} />
    </Wrapper>
  );
}
