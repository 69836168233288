import React, { useEffect, useRef } from "react";
import { SmallButton } from "components/Button";
import { useRouter } from "next/router";
import { TYPE_SIZE, BREAKPOINT } from "utils/styles";

import Link from "next/link";

import styled from "styled-components";

import {
  BORDER_RADIUS,
  BORDER_RADIUS_SMALL,
  DEFAULT_BOX_SHADOW,
  COLORS,
  getAudienceTypeColor,
  getNarrativeTypeColor,
} from "utils/styles";

import {
  AUDIENCE_TYPES_ARRAY,
  NARRATIVE_TYPES_ARRAY,
  getAudienceEmoji,
  getAudienceSlug,
  getNarrativeSlug,
} from "posts/constants";

import { CSSTransition } from "react-transition-group";
import { useClickAway } from "use-click-away";
export const SMALL_SCREEN_BREAKPOINT = "40rem";

const Drawer = styled.div`
  ${BORDER_RADIUS}
  ${DEFAULT_BOX_SHADOW};
  background: ${COLORS.WHITE};
  display: none;
  inset: 0 auto auto 0;
  line-height: 1.35rem;
  margin: 1rem;
  max-width: 100%;
  min-width: 23rem;
  padding: 0.25rem;
  position: fixed;
  width: 23rem;
  z-index: 1001;

  white-space: initial;

  @media (min-width: ${SMALL_SCREEN_BREAKPOINT}) {
    top: 0 !important;
  }

  opacity: 0;
  height: 50px;
  transform: translateY(5px);
  transition: all 400ms ease-in-out;

  &.flyIn-enter,
  &.flyIn-enter-active,
  &.flyIn-enter-done,
  &.flyIn-exit,
  &.flyIn-exit-active {
    display: block;
    overflow: hidden;
  }

  &.flyIn-enter-active,
  &.flyIn-enter-done {
    opacity: 1;
    transform: translateY(0px);
    height: 640px;

    @media (max-height: 700px) {
      height: 100vh;
      overflow: scroll;
    }
  }

  &.flyIn-exit,
  &.flyIn-exit-active,
  &.flyIn-exit-done {
    opacity: 0;
    transition: all 300ms ease-in;
  }

  @media (max-width: ${SMALL_SCREEN_BREAKPOINT}) {
    border-radius: 0;
    display: block;
    height: 100vh;
    inset: 0 auto auto 100vw;
    margin: 0 !important;
    min-width: 0;
    overflow: scroll-Y;
    padding: 1rem;
    width: 100vw;

    &.flyIn-enter-active,
    &.flyIn-enter-done {
      opacity: 1;
      transform: translate3d(-100vw, 0, 0);
      transition: transform 300ms ease-out;
    }
    &.flyIn-exit {
      transform: translate3d(-100vw, 0, 0);
      transition: transform 300ms ease-out;
    }

    &.flyIn-exit-active,
    &.flyIn-exit-done {
      opacity: 1;
      transform: translate3d(-200vw, 0, 0);
      transition: transform 300ms ease-out;
    }
  }
`;

const DrawerFooter = styled.div`
  font-size: ${TYPE_SIZE.S};
  color: ${COLORS.MEDIUM_GRAY};
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      color: ${COLORS.BLACK};
    }
  }
`;

const DrawerSection = styled.div.attrs({ className: "mx-3" })`
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  font-size: ${TYPE_SIZE.S};

  @media (max-width: ${SMALL_SCREEN_BREAKPOINT}) {
    margin: 0.25rem !important;
  }
`;

const DrawerSectionHeadingSupplementIcon = styled.i.attrs({
  className: "material-icons-round mr-2",
})`
  color: ${COLORS.GRAY};
`;
const DrawerSectionHeadingSupplement = styled.span`
  color: ${COLORS.MEDIUM_GRAY};
  display: flex;
  opacity: 0;
  transition: opacity 300ms ease-out;
  @media ${BREAKPOINT.M} {
    opacity: 1;
  }
`;

const DrawerSectionHeadingText = styled.span`
  align-items: center;
  display: flex;
  text-decoration: none;
  letter-spacing: 0.25px;
  i {
    font-size: 1.1rem;
  }
`;
const DrawerSectionHeading = styled.span`
  align-items: center;
  color: ${COLORS.BLACK};
  display: flex;
  font-size: ${TYPE_SIZE.S};
  font-weight: medium;
  justify-content: space-between;

  padding-right: 0;
  text-decoration: none;
  transition: padding 300ms ease-out;

  &:hover {
    color: ${COLORS.BLACK};
    padding-left: 0.25rem;
    text-decoration: none;

    &:hover ${DrawerSectionHeadingSupplement} {
      opacity: 1;
      transition: opacity 300ms ease-out;
    }
  }

  a& {
    cursor: pointer;
  }
`;

const DrawerSectionButtons = styled.div.attrs({ className: "my-1" })`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem !important;
  margin-left: -0.2rem;
`;

const DrawerHeading = styled.h2`
  align-items: center;
  display: flex;
  font-size: ${TYPE_SIZE.S};
  font-weight: medium;
  flex-direction: column;
  align-items: flex-start;
  /* margin-bottom: 0.5rem; */
  a {
    font-family: "mabry-medium";
    &:hover {
      opacity: 0.6;
    }
  }
  p {
    margin-top: 0.5rem;
    line-height: 1.5;
    margin-bottom: 0;
    @media ${BREAKPOINT.M} {
      display: none;
    }
  }
`;

const DrawerButton = styled.a.attrs({ className: "ml-0 py-1 px-2" })`
  ${BORDER_RADIUS_SMALL}
  background-color: ${COLORS.LIGHTEST_GRAY};
  flex: 0 1;
  font-size: ${TYPE_SIZE.S};
  font-weight: normal;
  white-space: nowrap;
  cursor: pointer;
  
  &:hover{
    filter: saturate(1.1) brightness(0.95);
  }

  &, &:hover {
    color: inherit;
    text-decoration: none;
    
    
  }
`;

const DrawerNarrativeButton = styled(SmallButton)`
  cursor: pointer;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || COLORS.GRAY} !important;

  &:hover {
    filter: saturate(1.1) brightness(1.1);
  }
`;

const NavLinkText = styled.span.attrs({ className: "mabry-medium" })``;

const NavLink = styled.a`
  align-items: center;
  border: none;
  color: ${COLORS.DARKEST_GRAY};
  cursor: pointer;
  display: ${({ inline }) => (inline ? "inline" : "flex")};
  outline: none;
  text-decoration: none !important;

  &:hover,
  &:focus {
    color: inherit;
    opacity: 1;
    text-decoration: none !important;
`;

const AboutLink = styled.span`
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
`;

export default function MenuDrawer({ show, close }) {
  const drawerRef = useRef("");

  const router = useRouter();

  useClickAway(drawerRef, close);

  useEffect(() => {
    router.events.on("routeChangeComplete", close);
    return () => {
      router.events.off("routeChangeComplete", close);
    };
  }, []);

  return [
    <CSSTransition in={show} classNames="flyIn" timeout={300} key="transition">
      <Drawer ref={drawerRef}>
        <NavLink className="m-2 d-block d-md-none" onClick={close}>
          <i className="material-icons-round mr-2">arrow_back</i>
        </NavLink>
        <DrawerSection className="px-1 py-3">
          <DrawerHeading>
            <Link href="/">
              <NavLink>
                <img
                  width={24}
                  className="mr-2"
                  alt="Harmony Labs"
                  src="/images/hl-logo.png"
                />
                Narrative Observatory
              </NavLink>
            </Link>
            <p>
              The people we want to reach don’t spend their time thinking about
              us and our issue. So what stories do they live inside? What voices
              do they value?{" "}
              <Link href="/about">
                <NavLink inline href="/about">
                  <AboutLink>Learn more&hellip;</AboutLink>
                </NavLink>
              </Link>
            </p>
          </DrawerHeading>
        </DrawerSection>
        <DrawerSection className="px-1 py-3">
          <Link href="/audiences">
            <DrawerSectionHeading as="a">
              <DrawerSectionHeadingText>
                <i className="material-icons-round mr-2">people</i>
                <NavLinkText>Audiences</NavLinkText>
              </DrawerSectionHeadingText>
              <DrawerSectionHeadingSupplement>
                All
                <DrawerSectionHeadingSupplementIcon>
                  chevron_right
                </DrawerSectionHeadingSupplementIcon>
              </DrawerSectionHeadingSupplement>
            </DrawerSectionHeading>
          </Link>
          <DrawerSectionButtons>
            {AUDIENCE_TYPES_ARRAY.map((audienceType) => (
              <Link
                key={audienceType}
                href={"/audiences/" + getAudienceSlug(audienceType)}
              >
                <DrawerButton className="px-1 py-0 mr-2 mb-2 ml-0">
                  {getAudienceEmoji(audienceType)} {audienceType}
                </DrawerButton>
              </Link>
            ))}
          </DrawerSectionButtons>
        </DrawerSection>
        <DrawerSection className="px-1 py-3">
          <Link href="/narratives/">
            <DrawerSectionHeading as="a">
              <DrawerSectionHeadingText>
                <i className="material-icons-round mr-2">category</i>
                <NavLinkText>Narratives</NavLinkText>
              </DrawerSectionHeadingText>
              <DrawerSectionHeadingSupplement>
                All
                <DrawerSectionHeadingSupplementIcon>
                  chevron_right
                </DrawerSectionHeadingSupplementIcon>
              </DrawerSectionHeadingSupplement>
            </DrawerSectionHeading>
          </Link>
          <DrawerSectionButtons>
            {NARRATIVE_TYPES_ARRAY.map((narrativeType) => (
              <Link
                key={narrativeType}
                href={"/narratives/" + getNarrativeSlug(narrativeType)}
              >
                <DrawerNarrativeButton
                  className="px-1 py-0 m-1"
                  backgroundColor={getNarrativeTypeColor(narrativeType)}
                >
                  {narrativeType}
                </DrawerNarrativeButton>
              </Link>
            ))}
          </DrawerSectionButtons>
        </DrawerSection>
        <DrawerSection className="px-1 py-3">
          <Link href="/feed">
            <DrawerSectionHeading as="a">
              <DrawerSectionHeadingText>
                <i className="material-icons-round mr-2">trending_up</i>
                <NavLinkText>Stories &amp; Opportunities</NavLinkText>
              </DrawerSectionHeadingText>
            </DrawerSectionHeading>
          </Link>
        </DrawerSection>
        <DrawerSection className="px-1 py-3">
          <Link href="/about">
            <DrawerSectionHeading as="a">
              <DrawerSectionHeadingText>
                <i className="material-icons-round mr-2">description</i>
                <NavLinkText>Learn More</NavLinkText>
              </DrawerSectionHeadingText>
            </DrawerSectionHeading>
          </Link>
        </DrawerSection>
        <DrawerFooter className="px-1 pt-3 mx-3 mb-3">
          <a href="https://harmonylabs.org/" target="_blank">
            {" "}
            &copy; 2021 Harmony Labs
          </a>{" "}
          |{" "}
          <NavLink inline href="/legal">
            Legal
          </NavLink>
        </DrawerFooter>
      </Drawer>
    </CSSTransition>,
  ];
}
